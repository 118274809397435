<template>
  <div class="third-tier">
    <cdr-list :modifier="modifier">
      <li
        v-for="({ text, footnote, key }) in benefits"
        :key="key"
        class="third-tier__item"
      >
        <icon-check-sm />
        <span class="third-tier__text">{{ text }}<sup v-if="!!footnote">{{ footnote }}</sup></span>
      </li>
    </cdr-list>
  </div>
</template>

<script>
import { CdrList, IconCheckSm } from '@rei/cedar';

export default {
  name: 'ThirdTierBenefitsList',
  components: {
    CdrList,
    IconCheckSm,
  },
  props: {
    benefits: {
      type: Array,
      required: true,
    },
    isSmall: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    modifier() {
      return this.isSmall ? '' : 'inline';
    },
  },
};
</script>

<style lang="scss">

.third-tier {
  max-width: 600px;
  margin: 0 auto;
  @include cdr-md-mq-up {
    max-width: initial;
  }
}

.third-tier__item {
  display: grid !important;
  grid-auto-flow: column;
  align-items: center;
  margin: 0;
  grid-template-columns: $cdr-icon-size-lg 1fr;

  @include cdr-md-mq-up {
    column-gap: 1.2rem;
  }

  svg {
    fill: #BFB33E;
    width: $cdr-icon-size-lg;
    height: $cdr-icon-size-lg;
    padding: 0;
  }
}

.third-tier ul {
  @include cdr-md-mq-up {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 3fr 2.5fr repeat(3, 2fr);
  }
}

.third-tier__text {
  @include cdr-text-utility-sans-strong-300;

  sup {
    font-size: 0.8rem;
    font-weight: 600;
  }
}
</style>

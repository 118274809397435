<template>
  <div
    class="range-slider"
    :style="cssVars"
  >
    <div class="range-slider__meta">
      <cdr-text class="range-slider__label">
        {{ label }}
      </cdr-text>
      <span
        v-if="helperText"
        :id="`${id}-helper-text`"
        class="range-slider__helper-text"
        v-html="helperText"
      />
    </div>
    <label
      ref="labelEl"
      :for="id"
      class="range-slider__value"
    >
      {{ computedValueText }}
    </label>
    <input
      :id="id"
      ref="inputEl"
      class="range-slider__input"
      type="range"
      v-bind="conditionalProps"
      :aria-label="computedAriaLabel"
      :aria-valuemin="min"
      :aria-valuemax="max"
      :data-ui="dataUi"
      :min="min"
      :max="max"
      :value="value"
      @input="handleInput"
    >
    <div class="range-slider__range-labels">
      <cdr-text class="range-slider__range-label">
        {{ computedMinText }}
      </cdr-text>
      <cdr-text class="range-slider__range-label">
        {{ computedMaxText }}
      </cdr-text>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  reactive,
  ref,
  nextTick,
  onBeforeUnmount,
  onMounted,
} from 'vue';
import { CdrText } from '@rei/cedar';
import { CdrColorBackgroundBrandSpruce } from '@rei/cdr-tokens';

const props = defineProps({
  ariaLabel: {
    type: String,
    required: false,
    default: '',
  },
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  helperText: {
    type: String,
    required: false,
    default: '',
  },
  max: {
    type: Number,
    required: true,
  },
  min: {
    type: Number,
    required: true,
  },
  minMaxTextTemplate: {
    type: String,
    required: false,
    default: '',
  },
  value: {
    type: Number,
    required: true,
  },
  valueTextTemplate: {
    type: String,
    required: false,
    default: '',
  },
});

/*
 * State
 */
const state = reactive({
  labelWidth: 0,
  inputWidth: 0,
});

/*
 * Events
 */
const emit = defineEmits(['input']);

/*
 * $refs
 */
const labelEl = ref(null);
const inputEl = ref(null);

/*
 * Methods
 */
const getAndSetElementWidths = () => {
  if (labelEl.value && inputEl) {
    const nextLabelWidth = labelEl.value.clientWidth || 12;
    const nextInputWidth = inputEl.value.clientWidth;
    state.labelWidth = nextLabelWidth;
    state.inputWidth = nextInputWidth;
  }
};
const getTemplatedText = (value, template) => {
  const text = value?.toString ? value.toString() : value;
  if (typeof text !== 'string') return value;
  return template ? template.replace('#', text) : value;
};
const handleResize = () => nextTick(getAndSetElementWidths);
const handleInput = ({ target }) => {
  emit('input', target?.value);
  // with only one nextTick, this method returns the element width from the previous render
  // with double nextTick, the width from the latest render is returned
  nextTick(handleResize);
};
const handleUnload = () => {
  inputEl.value.setAttribute('value', props.value);
};

/*
 * Computed
 */
const computedAriaLabel = computed(() => props.ariaLabel || props.label);
const computedMaxText = computed(() => getTemplatedText(props.max, props.minMaxTextTemplate));
const computedMinText = computed(() => getTemplatedText(props.min, props.minMaxTextTemplate));
const computedValueText = computed(() => getTemplatedText(props.value, props.valueTextTemplate));
/*
 * This object allows us to cleanly v-bind a number of properties that
 * aren't always active
 */
const conditionalProps = computed(() => {
  const {
    id,
    valueTextTemplate,
    helperText,
  } = props;

  return {
    ...(valueTextTemplate ? { 'aria-valuetext': computedValueText.value } : {}),
    ...(helperText ? { 'aria-describedby': `${id}-helper-text` } : {}),
  };
});
const progress = computed(() => {
  const { min, max, value } = props;
  return ((value - min) / (max - min)) * 100;
});
/*
 * The label tracks with the range slider thumb as the user moves the thumb, but it needs
 * to shift so that it's not overlapping outside of the container. This computes the
 * correct amount to shift the label.
 */
const labelTransformPercentage = computed(() => {
  const { inputWidth, labelWidth } = state;
  // const labelPercentage = Math.round((labelWidth / inputWidth) * 1000) / 10;

  const pixelProgress = (progress.value / 100) * inputWidth;
  const halfLabelWidth = labelWidth / 2;
  let output;

  if (pixelProgress <= halfLabelWidth) {
    output = 0;
  } else if (pixelProgress >= (inputWidth - halfLabelWidth)) {
    output = inputWidth - labelWidth;
  } else {
    output = pixelProgress - halfLabelWidth;
  }

  return output;
});
const cssVars = computed(() => ({
  '--range-slider-progress-value': progress.value,
  '--range-slider-progress-lower-color': CdrColorBackgroundBrandSpruce,
  '--range-slider-progress-upper-color': 'transparent',
  '--range-slider-progress-percentage': `${progress.value}%`,
  '--range-slider-label-percentage': `${labelTransformPercentage.value}px`,
}));
const dataUi = computed(() => `range-slider-${props.id}`);

onMounted(() => {
  if (typeof window !== 'undefined') {
    getAndSetElementWidths();
    window.addEventListener('resize', handleResize);
    window.addEventListener('beforeunload', handleUnload);
  }
});
onBeforeUnmount(() => {
  if (typeof window !== 'undefined') {
    window.removeEventListener('resize', handleResize);
    window.removeEventListener('beforeunload', handleUnload);
  }
});
</script>

<style lang="scss">
.range-slider {
  width: 100%;
}

@mixin thumb-animation-properties {
  transition-duration: $cdr-duration-1-x;
  transition-timing-function: $cdr-timing-function-ease;
  transition-property: transform,background-image,box-shadow,border;
}
@mixin thumb-focus-properties {
  box-shadow: 0 0 2px 0.2rem $cdr-color-border-button-primary-active;
  border-color: white;
  transform: scale(1.5);
}
@mixin thumb-active-properties {
  background-image: radial-gradient(
    circle,
    $cdr-color-background-brand-spruce 0%,
    $cdr-color-background-brand-spruce 27%,
    $cdr-color-background-primary 29%,
    $cdr-color-background-primary 43%,
    $cdr-color-background-brand-spruce 46%
  );
}
.range-slider__input {
  -webkit-appearance: none;
  cursor: pointer;
  padding: $cdr-space-half-x 0;
  margin: 0 auto;
  width: 100%;
  box-shadow: 0 0 0 0 transparent;
  mix-blend-mode: darken;

  &::-webkit-slider-thumb {
    @include thumb-animation-properties;
  }

  &::-moz-range-thumb {
    @include thumb-animation-properties;
  }

  &::-ms-thumb {
    @include thumb-animation-properties;
  }

  &:focus {
    outline: none;
    &::-webkit-slider-thumb {
      @include thumb-focus-properties;
    }
    &::-moz-range-thumb {
      @include thumb-focus-properties;
    }
    &::-ms-thumb {
      @include thumb-focus-properties;
    }
  }

  &:active {
    &::-webkit-slider-thumb {
      @include thumb-active-properties;
    }
    &::-moz-range-thumb {
      @include thumb-active-properties;
    }
    &::-ms-thumb {
      @include thumb-active-properties;
    }
  }

}

// Thumb
@mixin slider-thumb {
  height: 1.6rem;
  width: 1.6rem;
  background-color: $cdr-color-text-brand;
  border: 1px solid transparent;
  padding: 0;
  border-radius: 50%;
  position: relative;
  top: -0.4rem;
  transition-property: transform,background-image,box-shadow,border;
}
.range-slider__input::-webkit-slider-thumb {
  @include slider-thumb;
  -webkit-appearance: none;
  &:hover, &:active {
    @include thumb-focus-properties;
  }
}
.range-slider__input::-moz-range-thumb {
  @include slider-thumb;
  -moz-appearance: none;
  &:hover, &:active {
    @include thumb-focus-properties;
  }
}
.range-slider__input::-ms-thumb {
  @include slider-thumb;
  &:hover, &:active {
    @include thumb-focus-properties;
  }
}

// Track
@mixin slider-track {
  background-repeat: no-repeat;
  position: relative;
  background-color: $cdr-color-border-primary;
  background-image: linear-gradient(to right,
        var(--range-slider-progress-lower-color) 0%,
        var(--range-slider-progress-lower-color) var(--range-slider-progress-percentage),
        var(--range-slider-progress-upper-color) var(--range-slider-progress-percentage),
        var(--range-slider-progress-upper-color) 100%);
  border-radius: 0.4rem;
  border: solid 1px $cdr-color-border-button-primary-rest;
}
.range-slider__input::-webkit-slider-runnable-track {
  @include slider-track;
  height: 0.8rem;
}
.range-slider__input::-moz-range-track {
  @include slider-track;
}
.range-slider__input::-ms-track {
  @include slider-track;
}

.range-slider__meta {
  padding-bottom: $cdr-space-half-x;
}

.range-slider__label {
  @include cdr-text-utility-sans-200;
}

.range-slider__helper-text {
  display: inline-block;
  @include cdr-text-utility-sans-100;
  color: $cdr-color-text-secondary;
}

.range-slider__value {
  display: inline-block;
  @include cdr-text-heading-sans-300;
  margin-bottom: $cdr-space-quarter-x;
  position: relative;
  left: var(--range-slider-label-percentage);
}

.range-slider__range-labels {
  display: flex;
  justify-content: space-between;
}

.range-slider__range-label {
  @include cdr-text-utility-sans-100;
  color: $cdr-color-text-secondary;
}
</style>

import '@rei/cedar/dist/cdr-reset.css';
import '@rei/cedar/dist/cdr-fonts.css';

import '@rei/cedar/dist/style/cdr-container.css';
import '@rei/cedar/dist/style/cdr-button.css';
import '@rei/cedar/dist/style/cdr-text.css';
import '@rei/cedar/dist/style/cdr-link.css';
import '@rei/cedar/dist/style/cdr-icon.css';
import '@rei/cedar/dist/style/cdr-img.css';
import '@rei/cedar/dist/style/cdr-list.css';
import '@rei/cedar/dist/style/cdr-grid.css';

import createApp from './main';

function getClientPageData(pageDataId) {
  const id = pageDataId || 'modelData';
  const model = window.document.getElementById(id);
  return model === null ? { pageData: {} } : JSON.parse(model.innerHTML);
}

const { pageData: props } = getClientPageData();

const { app } = createApp(props);
app.mount('#app');

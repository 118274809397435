export const getRewardsCalcSliderModels = ({
  frontendStrings,
}) => {
  const allCoopPurchases = {
    initialValue: parseFloat(frontendStrings.allCoopInitialValue),
    order: parseFloat(frontendStrings.allCoopOrder),
    min: parseFloat(frontendStrings.allCoopMin),
    max: parseFloat(frontendStrings.allCoopMax),
    percent: parseFloat(frontendStrings.allCoopPercent),
    id: frontendStrings?.allCoopId,
    label: frontendStrings?.allCoopLabel,
    helperText: frontendStrings?.allCoopHelperText,
    src: 'https://www.rei.com/assets/credit-card/capital-one/bike/live.svg',
    alt: 'a graphic of a bicycle',
  };
  const everydayPurchases = {
    initialValue: parseFloat(frontendStrings.everydayInitialValue),
    order: parseFloat(frontendStrings.everydayOrder),
    min: parseFloat(frontendStrings.everydayMin),
    max: parseFloat(frontendStrings.everydayMax),
    percent: parseFloat(frontendStrings.everydayPercent),
    id: frontendStrings?.everydayId,
    label: frontendStrings?.everydayLabel,
    helperText: frontendStrings?.everydayHelperText,
    src: 'https://www.rei.com/assets/credit-card/capital-one/groceries/live.svg',
    alt: 'a graphic of a bag of groceries',
  };
  return {
    allCoopPurchases,
    everydayPurchases,
  };
};

export const getRewardsCalculatorModel = ({
  frontendStrings = {},
  cmsModel = {},
}) => {
  const sliderModels = getRewardsCalcSliderModels({ frontendStrings });
  const {
    ctaHref: href,
    ctaText: text,
    rewardDisclaimer,
    memberRewardMention,
  } = frontendStrings;
  const {
    heading,
    image,
    subheading,
  } = cmsModel;
  const cta = { href, text };
  return {
    ...sliderModels,
    ...cmsModel,
    cta,
    rewardDisclaimer,
    heading,
    href,
    memberRewardMention,
    image,
    subheading,
    text,
  };
};

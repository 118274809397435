<template>
  <cdr-container class="just-for-members">
    <cdr-img
      :src="airplaneImage?.src"
      :alt="airplaneImage?.alt"
      class="just-for-members__airplane"
    />
    <cdr-text
      tag="h2"
      class="just-for-members__heading"
    >
      <span v-html="heading" />
    </cdr-text>
    <p
      class="just-for-members__message"
      v-html="message"
    />
    <!-- /* c8 ignore start */ -->
    <add-membership-to-cart
      v-show="!!addMembershipToCart?.text"
      :cta-text="addMembershipToCart?.text"
      :secondary-button-style="true"
      class="just-for-members__add-membership-button__secondary"
      data-analytics-config="exclude-all"
      @click="handleAddMembershipClick"
    />
    <!-- /* c8 ignore end */ -->
    <div
      v-show="!!alreadyMemberText"
      class="just-for-members__sign-in-wrapper"
    >
      <cdr-text tag="p">
        {{ alreadyMemberText }}
      </cdr-text>
      <!-- /* c8 ignore start */ -->
      <cdr-link
        v-if="signInCta"
        :href="signInCta?.target"
        class="just-for-members__sign-in-link"
        @click="handleSignInClick"
      >
        <!-- /* c8 ignore end */ -->
        {{ signInCta?.text }}
      </cdr-link>
    </div>
  </cdr-container>
</template>

<script>
import {
  CdrContainer, CdrImg, CdrLink, CdrText,
} from '@rei/cedar';
import AddMembershipToCart from '@rei/add-membership-to-cart';
import { metricsLink, metricsLinkAddToCart } from '../../../utils/analytics';

export default {
  name: 'JustForMembers',
  components: {
    CdrImg,
    CdrText,
    CdrContainer,
    AddMembershipToCart,
    CdrLink,
  },
  props: {
    airplaneImage: {
      type: Object,
      required: true,
    },
    addMembershipToCart: {
      type: Object,
      required: true,
    },
    alreadyMemberText: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    signInCta: {
      type: Object,
      required: false,
      default: () => null,
    },
  },

  methods: {
    /* c8 ignore start */
    handleAddMembershipClick() {
      metricsLinkAddToCart('buy membership');
    },
    handleSignInClick() {
      metricsLink(`jfm_sign_in_${this.signInCta?.text || 'Sign in'}`);
    },
    /* c8 ignore end */
  },
};
</script>

<style lang="scss">

.just-for-members {
    text-align: center;
    margin-bottom: $cdr-space-one-x;

    &__airplane {
      width: 250px;
      margin-bottom: $cdr-space-one-x;

      @include cdr-md-mq-up {
        width: 330px;
      }
    }

    &__heading {
      @include cdr-text-utility-serif-600;

      color: $cdr-color-text-brand;
      margin-bottom: $cdr-space-one-x;

      @include cdr-md-mq-up {
        @include cdr-text-heading-display-900;
        text-wrap: unset;
        padding-left: 12rem;
        padding-right: 12rem;
      }
    }

    &__message {
        @include cdr-text-body-300;
        margin-bottom: $cdr-space-one-x;

        @include cdr-md-mq-up {
          padding-left: 22rem;
          padding-right: 22rem;
        }
    }

    &__sign-in-wrapper {
      display: flex;
      justify-content: center;
      p, a, span {
        @include cdr-text-utility-sans-200;
      }
    }

    &__sign-in-link {
      margin-left: $cdr-space-half-x;
    }

    &__add-membership-button__secondary {
      margin-bottom: $cdr-space-one-x;
      margin-left: -$cdr-space-half-x;
      margin-right: -$cdr-space-half-x;
    }
}
</style>

<template>
  <div class="thumbnail-nav">
    <ul
      class="thumbnail-nav__track"
      tabindex="0"
    >
      <li
        v-for="(props, index) in slides"
        :key="index"
        :class="liClass || null"
      >
        <slot
          v-bind="props"
          name="slide"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ThumbnailNav',
  props: {
    liClass: {
      type: String,
      required: false,
      default: '',
    },
    slides: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">

.thumbnail-nav {
  overflow: auto;
  padding-bottom: $cdr-space-half-x;
}

.thumbnail-nav__track {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: $cdr-space-one-and-a-half-x;
  padding: 0 0 0 $cdr-space-one-x;
  margin: 0;
  list-style: none;
}

.thumbnail-nav__card {
  padding-left: $cdr-space-one-and-a-half-x;
  &:last-child {
    padding-right: $cdr-space-one-and-a-half-x;
  }
}

.thumbnail-nav__card-inner {
  display: flex;
  align-items: flex-end;
  position: relative;
  padding: $cdr-space-inset-one-x;
  min-width: 260px;
  height: 160px;
  border-radius: $cdr-radius-softer * 2;
  overflow: hidden;
}
</style>

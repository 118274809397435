<template>
  <div class="benefit-card">
    <cdr-img
      :alt="image?.alt"
      :src="image?.src"
      class="benefit-card__image"
    />
    <div class="benefit-card__content">
      <h3
        class="benefit-card__heading"
        v-html="heading"
      />
      <cdr-text
        class="benefit-card__subheading"
      >
        <span v-html="subheading" />
      </cdr-text>
      <div
        v-if="pill"
        class="benefit-card__pill"
        v-html="pill"
      />
    </div>
  </div>
</template>

<script>
import { CdrImg, CdrText } from '@rei/cedar';

export default {
  name: 'BenefitCardComponent',
  components: {
    CdrImg,
    CdrText,
  },
  props: {
    alt: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    pill: {
      type: String,
      required: false,
      default: '',
    },
    subheading: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">

.benefit-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 264px;
  height: 100%;
}

.benefit-card__image {
  min-height: 187px;
  border-top-left-radius: $cdr-radius-softer;
  border-top-right-radius: $cdr-radius-softer;
}

.benefit-card__content {
  text-align: center;
  background-color: $cdr-color-background-secondary;
  border-bottom-left-radius: $cdr-radius-softer;
  border-bottom-right-radius: $cdr-radius-softer;
  padding: $cdr-space-inset-one-x-stretch;
  color: $cdr-color-text-brand;
  flex: 1;
  sup {
    font-size: .8rem;
    font-weight: 600;
  }
}

.benefit-card__pill {
  @include pill;
  display: inline-block;
  margin-top: $cdr-space-half-x;
}

.benefit-card__heading.benefit-card__heading {
  @include cdr-text-heading-sans-400;
  margin-bottom: $cdr-space-three-quarter-x;
  sup {
    font-size: 1rem;
    font-weight: 600;
  }
  @include cdr-md-mq-up {
    @include cdr-text-heading-sans-600;
  }
}
.benefit-card__subheading.benefit-card__subheading {
  color: $cdr-color-text-brand;
  @include cdr-text-utility-sans-300;
  @include cdr-md-mq-up {
    @include cdr-text-body-400;
  }
}
</style>
